<template>
    <b-container>
        <b-skeleton-wrapper
            v-if="semester && semester.id"
            :loading="loading"
            :key="semester.id"
        >
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                </b-card>
            </template>
            <b-card class="text-center">
                <h2>
                    <font-awesome-icon
                        icon="chalkboard-teacher"
                    ></font-awesome-icon>
                    Classes
                </h2>
                <div class="mt-3">
                    <router-link
                        class="sc-btn pill"
                        :to="{ name: 'admin_form', params: { formKey: 'add-class' } }"
                    >
                        <font-awesome-icon
                            class="mr-1"
                            icon="plus-circle"
                        ></font-awesome-icon>
                        Add Class
                    </router-link>
                    <a
                        href="#"
                        class="sc-btn pill ml-2"
                        @click="exportTable()"
                    >
                        <font-awesome-icon
                            icon="file-download"
                            class="mr-1"
                        ></font-awesome-icon>
                        Export
                    </a>
                    <router-link
                        class="sc-btn pill ml-2"
                        :to="{ name: 'admin_import', params: { import_name: 'classes' } }"
                    >
                        <font-awesome-icon
                            icon="file-upload"
                            class="mr-1"
                        ></font-awesome-icon>
                        Import
                    </router-link>
                    <!--
                        <b-dropdown class="m-1">
                            <template #button-content>
                                <font-awesome-icon icon="file-import"></font-awesome-icon> Import
                            </template>
                            <b-dropdown-item>
                                <font-awesome-icon icon="file-excel"></font-awesome-icon> Import From Excel
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <font-awesome-icon icon="file-csv"></font-awesome-icon> Import From CSV
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <font-awesome-icon icon="file-invoice"></font-awesome-icon> Import From Form
                            </b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown class="m-1">
                            <template #button-content>
                                <font-awesome-icon icon="file-download"></font-awesome-icon> Export
                            </template>
                        </b-dropdown>
                    -->
                </div>
            </b-card>
            <b-card
                class="mt-2"
                title="Filter"
                title-tag="h5"
            >
                <b-input placeholder="Search information..." v-model="filter"></b-input>
                <b-checkbox-group
                    class="mt-3"
                    v-model="show"
                >
                    <b-check
                        value="available"
                    >
                        Spots Available 
                    </b-check>
                    <b-check
                        value="full"
                    >
                        Full
                    </b-check>
                    <b-check
                        @change="fetchClasses()"
                        value="show_removed"
                    >
                        Recently Removed
                    </b-check>
                </b-checkbox-group>
                <!--<select-semester
                    v-model="selectedSemesterId"
                ></select-semester>-->
            </b-card>
            <div
                class="scroll-x"
            >
                <b-table
                    ref="mainTable"
                    class="mt-2 w-100"
                    :busy="loading"
                    striped
                    bordered
                    hover
                    :items="classes"
                    :fields="fields"
                    selectable
                    select-mode="single"
                    @row-selected="onStudentSelected"
                    :filter="filter"
                    empty-text="There are no results that match your criteria."
                    empty-filtered-text="There are no results that match your criteria."
                    :show-empty="true"
                >
                    <template #cell(pos)="row">
                        {{ row.index + 1 }}
                    </template>
                    <template #cell(class_name)="{ item }">
                        {{ item.class_name }} <b-badge v-if="item.class_full" variant="danger">FULL</b-badge>
                    </template>
                    <template #cell(email)="{ item }">
                        <a :href="'mailto:' + item.email">{{ item.email }}</a>
                    </template>
                    <template #cell(actions)="{ item }">
                        <b-btn-group>
                            <router-link
                                is="b-button"
                                variant="link"
                                :to="{ name: 'admin_form', params: { formKey: 'edit-class' }, query: { id: item.id } }"
                            >
                                <font-awesome-icon
                                    icon="pencil-alt"
                                ></font-awesome-icon>
                            </router-link>
                            <b-button
                                variant="link"
                                @click="deleteClass(item)"
                            >
                                <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                            </b-button>
                            <router-link
                                is="b-button"
                                variant="link"
                                :to="{ name: 'single_class', params: { id: item.id }}"
                            >
                                <font-awesome-icon icon="info-circle"></font-awesome-icon>
                            </router-link>
                        </b-btn-group>
                    </template>
                    <template #cell(is_active)="row">
                        <b-checkbox
                            @change="setActive"
                            :value="row.item.is_active"
                        ></b-checkbox>
                    </template>
                    <template #empty-text>
                        There are no records to fetch.
                    </template>
                </b-table>
            </div>
        </b-skeleton-wrapper>
        <b-container v-else>
            <i>
                <b>Cannot view classes: </b>
                No semester is in progress.
                <router-link :to="{ name: 'semesters' }">Click here</router-link>
                to select or create a new semester.
            </i>
        </b-container>
    </b-container>
</template>
<script>
import { mapState } from 'vuex';
import { downloadFile } from '@/api/downloadFile';

export default {
    props: ["class_id"],
    data() {
        return {
            loading: false,
            selectedClass: {},
            show: [ "available" ],
            filter: "",
            fields: [
                {
                    key         : "pos",
                    label       : "#",
                    sortable    : false
                },
                {
                    key         : "class_name",
                    label       : "Class Name",
                    sortable    : true
                },
                {
                    key         : "teacher_name",
                    label       : "Teacher Names",
                    sortable    : true
                },
                {
                    key         : "start_time",
                    sortable    : true,
                    formatter   : (value) => {
                        if (value == null) return "";
                        return this.$moment("2021-07-12T" + value).format("LT");
                    }
                },
                {
                    key         : "end_time",
                    sortable    : true,
                    formatter   : (value) => {
                        if (value == null) return "";
                        return this.$moment("2021-07-12T" + value).format("LT");
                    }
                },
                {
                    key         : "age_min",
                    sortable    : true
                },
                {
                    key         : "age_max",
                    sortable    : true
                },
                {
                    key         : "min_students",
                    sortable    : true
                },
                {
                    key         : "max_students",
                    sortable    : true
                },
                {
                    key         : "class_location",
                    sortable    : true
                },
                {
                    key         : "cost",
                    sortable    : true,
                    formatter   : (value) => {
                        if (typeof value == "number")
                            return "$" + value.toFixed(2);
                        return "Not given";
                    }
                },
                {
                    key: "actions"
                },
            ],
            items: [],
            showDetails: false,
            selectedSemesterId: null
        };
    },
    mounted() {
        this.selectedSemesterId = this.semester.id;
        this.fetchClasses();
    },
    methods: {
        fetchClasses() {
            this.loading = true;
            this.$api.get(
                "/api/admin/classes",
                {
                    params: {
                        semester_id  : this.selectedSemesterId,
                        show_removed : this.show.includes("show_removed")
                    }
                }
            ).then(({ data }) => {
                this.items = data.classes;
                this.loading = false;
            });
        },
        deleteClass(class_) {
            this.$api.post(
                `/api/admin/remove-class/${class_.id}`,
                this.classModel
            ).then(({ data }) => {
                this.$bvToast.toast(data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                class_.removed     = data.removed;
                this.showDetails   = false;
                this.selectedClass = null;
                this.$refs.mainTable.clearSelected();
            });
        },
        onStudentSelected(class_) {
            console.log(class_);
            this.showDetails   = true;
            this.selectedClass = class_[0];
        },
        showActive(row) {
            return row.is_active == true;
        },
        exportTable() {
            downloadFile(
                `/api/admin/export/classes/download.xlsx?semester_id=${this.selectedSemesterId}`,
                `${this.semester.label} Classes.xlsx`
            );
        }
    },
    computed: {
        classes() {
            return this.items.filter((item) => {
                if (!item.removed || this.show.includes("show_removed"))
                {
                    if      (this.show.includes("full") && this.show.includes("available")) return true;
                    else if (this.show.includes("full"))       return item.class_full;
                    else if (this.show.includes("available"))  return !item.class_full;
                }
                return false;
            });
        },
        ...mapState(["semester"])
    },
    watch: {
        semester() {
            this.selectedSemesterId = this.semester.id;
        },
        selectedSemesterId() {
            this.fetchClasses();
        }
    }
  }
</script>
<style scoped>
    .scroll-x {
        max-width:  100%;
        overflow-x: scroll;
    }
</style>